.card-container-admin {
    margin-top: 1.5vh;
    height: 10vh;
    width: 100%;
    justify-content: center;
  
  }
  
  .card-body-admin {
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #4C5574;
    border-left: 25px solid #4C5574;
    background-color: white;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out,background-color 0.3s;
  }
  
  .card-container-admin:hover .card-body-admin {
    background-color: #ebebeb;
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .card-title-admin {
    font-size: 100%;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin: 0;
  }
  
  .card-text-admin {
    font-size: 85%;
    font-family: 'Poppins', sans-serif;
    color:#4C5574;
    font-weight: 400;
    overflow:hidden;
  
  }
  
  @media (min-width: 768px) {
    .card-body-admin {
      width: 90%; 
    }
  
    .card-title-admin {
      font-size: 100%;
      color:#4C5574;
    }
  
    .card-text-admin {
      font-size: 85%;
      color:#4C5574;
    }
  
    .card-body-admin {
      border-radius: 5px;
      border: 1px solid #4C5574;
      border-left: 25px solid #4C5574;
      background-color: white;
    }
  }