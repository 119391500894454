.home-screen-container{
  min-height: 100vh;
  user-select: none;

}

.home-images {
  width: 100%;
  height: 100%;
  flex-direction: row-reverse;
}

.img-mobile-inf {
  list-style-position: inside;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 35%;
  height: auto;
  z-index: -1;
}

.img-mobile-sup {
  position: fixed;
  height: auto;

  margin-top: 3vw;
  right: 0;
  width: 40%;
  height: auto;
  z-index: -1;
}

.img-desktop {
  display: none;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* Estilos para dispositivos de escritorio */
@media (min-width: 1001px) {

  .img-mobile-inf {
    display: none;
    
  }

  .img-mobile-sup {
    display: none;
  }

  .img-desktop {
    position: fixed;
    margin-top: -30px;
    display: block;
    height: auto;
    max-height: 88vh;
    z-index: 2;
    right: 0;
  }

  .container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
}
