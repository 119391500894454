.home-container{
    padding-top: 0vh;
}

.home-title{
    font-size: large;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}
.iframe-title{
    font-size: large;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;  
}

.first-title {
    font-size: large;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1;
    
}

.cards-container {
    margin-top: 2%;
} 

.iframe-container{
    padding: 0;
    margin: 0;
    left: 0;
    right: 0;
    height:100%;

    display:flex;
    flex-direction:column;
    width:100vw;
}

iframe{
    height:100%
}

@media (min-width: 1001px) {
    .home-container{
        padding-top: 1vh;
        display: flex;
        height: 100%;
    }

    .iframe-container{

        padding: 0;
        margin: 0;
        left: 0;
        right: 0;
        height:unset;

        display:flex;
        flex-direction:column;
        width:100%;
    }



    .home-title{
        padding-left:0;
    }
}