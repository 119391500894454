.home-screen-container-admin{
    min-height: 100vh;
  }
  
  .home-images-admin {
    width: 100%;
    height: 100%;
  }
  
  .img-mobile-inf-admin {
    list-style-position: inside;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 35%;
    height: auto;
    z-index: -1;
  }
  
  .img-mobile-sup-admin {
    position: fixed;
    margin-top: 3vw;
    right: 0;
    width: 40%;
    height: auto;
    z-index: -1;
  }
  
  .img-desktop-admin {
    display: none;
  }
  
  /* Estilos para dispositivos de escritorio */
  @media (min-width: 1001px) {
  
    .home-images-admin {
    }
  
    .img-mobile-inf-admin {
      display: none;
      
    }
  
    .img-mobile-sup-admin {
      display: none;
    }
  
    .img-desktop-admin {
      display: block;
      height: 100vh;
      position: fixed;
      top: 0;
      z-index: 2;
      right: 0;
    }
  
    .container-admin {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
    
  }  