/* Estilos para dispositivos móviles */

.navbar-container {
    position:sticky;
    display: flex;
    background-color: #2C8FFE;
    width:100vw;

    align-items: center;

    max-height: 65px;
    height: auto;
    min-height: 58px;
    
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
    top:0;
}

.header-left-element{
    background-color: transparent;
    border-style: none;
    font-weight:600;
    color:white;
    font-family: 'Poppins';
    font-size: larger;

    height: 100%;
    text-align: center;
    padding-left: 25px;
}


.header-right-container{
    background-color: transparent;
    border-style: none;
    font-weight:800;
    color:white;
    font-family: 'Poppins';
}

.header-right-element {
    padding-right: 15px;
    margin-top:auto;
    margin-bottom:auto;
    max-height: 70%;
}
span.header-right-element {
    padding-right: 30px;
}

.navbar-container .container-fluid {
    padding: 0;
}

.navbar-image {
    max-height: 40px;
    padding-left: 25px;
    margin: 2%;
}

/* Estilos para dispositivos de escritorio */
@media (min-width: 1001px) {

.navbar-container {
    height: auto;
    padding: 0; /* Elimina el padding del Navbar */

}

.navbar-container .container-fluid {
    padding: 0 15px; /* Añade padding horizontal al container fluid para alinear con el contenido */
}

.navbar-image {
    height: 70px;
    padding-left: 25px;
    margin-top:1%;
    margin-bottom:1%;
}


}

