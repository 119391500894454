.footer-container {
  width: 100%;
  background-color: #2C8FFE;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 40px;
}

.text-white {
  color: white;
  line-height: 0;
  padding-top: 21px;
}

@media (max-width: 1000px) {
  .text-white {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }
}

/* Estilos para dispositivos de escritorio */
@media (min-width: 1001px) {
  .text-white {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }
}