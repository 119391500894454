.form-position {
  width: 90%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5%;
  margin-right: 5%;
}
.form-body {
  background-color: #2C8FFE;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
}
.in-form-body {
  padding: 10%;
}
.login-button .btn {
  background-color: white; /* Color de fondo blanco */
  color: #2C8FFE; /* Color del texto */
  border-color: #2C8FFE; /* Color del borde */
  border-radius: 10px; /* Borde redondeado */
  font-size: 14px; /* Tamaño de fuente */
  font-family: 'Poppins', sans-serif;
  width: 100%;
}
.login-title {

  font-weight: 200;
  font-size: 20px;
  line-height: 10%;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: white;
}
.login-subtitle {
  font-weight: bold;
  font-size: 32px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin-bottom: 1rem;
  color: white;
}
p.login-text {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

/* Estilos para dispositivos de escritorio */
@media (min-width: 1001px) {
  .form-position {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 30%;
    margin-right: 30%;
  }
  .form-body {
    background-color: #2C8FFE;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
  }
  .in-form-body {
    padding: 10%;
  }
  /* Estilos para el botón */
  .login-button .btn {
    background-color: white; /* Color de fondo blanco */
    color: #2C8FFE; /* Color del texto */
    border-color: #2C8FFE; /* Color del borde */
    border-radius: 10px; /* Borde redondeado */
    font-size: 14px; /* Tamaño de fuente */
    font-family: 'Poppins', sans-serif;
    width: 100%;
  }
  .login-button .btn:hover {
    background-color: #2C8FFE; /* Cambia el color de fondo al pasar el ratón */
    color: white; /* Cambia el color del texto al pasar el ratón */
    font-family: 'Poppins', sans-serif;
    
  }
  p.login-text {
      color: white;
      font-family: 'Poppins', sans-serif;
  }
}
