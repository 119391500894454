.home-admin-container{
    padding: 5vh;
    z-index: 0;
    height: 100%;
    flex:100;
}

.cards-container-admin {
    margin-top: 2%;
} 

@media (min-width: 1001px) {
    .home-admin-container {
        margin-left: 2%;
    }
    .cards-container-admin{
        width: 30%;
    
    }
}