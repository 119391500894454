.bad-user h4{ 
    font-size: 3.75vw;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: white;
    width: 100%;
    text-align: left;
    margin-top: 5%;
    margin-bottom: -10%;
    }

.error-permission{
    font-size: 3.75vw;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: white;
    width: 100%;
    text-align: left;
    margin-top: 5%;
    margin-bottom: -10%;
    }

.error-post{
    font-size: 3.75vw;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: white;
    width: 100%;
    text-align: left;
    margin-top: 5%;
    margin-bottom: -10%;
}

@media screen and (min-width: 1001px) {
    .bad-user h4 {
        font-size: 1.25vw;
        font-family: 'Poppins', sans-serif;
        color: white;
        width: 100%;
        text-align: left;
        margin-top: 5%;
        margin-bottom: -10%;
    }

    .error-permission{
        font-size: 1.25vw;
        font-family: 'Poppins', sans-serif;
        color: white;
        width: 100%;
        text-align: left;
        margin-top: 5%;
        margin-bottom: -10%;
        
    }

    .error-post{
        font-size: 1.25vw;
        font-family: 'Poppins', sans-serif;
        color: white;
        width: 100%;
        text-align: left;
        margin-top: 5%;
        margin-bottom: -10%;
       
    }
}