.users-screen-container{
  min-height: 100vh;
}

.users-cards-container{
  padding: 5vh;
  z-index: 0;
  height: 100%;
  flex:100;
}

.home-images-user {
  width: 100%;
  height: 100%;
}

.img-mobile-inf-user {
  list-style-position: inside;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 35%;
  height: auto;
  z-index: -1;
}

.img-mobile-sup-user {
  position: fixed;
  margin-top: 3vw;
  right: 0;
  width: 40%;
  height: auto;
  z-index: -1;
}

.img-desktop-user {
  display: none;
}

/* Estilos para dispositivos de escritorio */
@media (min-width: 1001px) {

  .home-images-user {
  }

  .img-mobile-inf-user {
    display: none;
    
  }

  .img-mobile-sup-user {
    display: none;
  }

  .img-desktop-user {
    display: block;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 2;
    right: 0;
  }

  .container-user {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .users-cards-container{
    margin-left: 2%;
    width: 30%
  }
 
}  